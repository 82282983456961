var CommonMixins = {
    methods: {
        genrateSrNo(i) {
            var sr = i + 1;
            return (30 * this.page_no) + sr;
        },

        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },

        selectedValues({ label, value }) {
            value = value.split(',');
            label = label.split(',');
            return value.map((d, i) => { return { label: label[i], value: d } });
        },
    }
};
export default CommonMixins;