<template>
  <div v-if="!$route.meta.plainLayout" :class="layoutContainerClass" @click="onDocumentClick">
    <AppTopBar :horizontal="menuMode === 'horizontal'" :topbarMenuActive="topbarMenuActive"
      :activeTopbarItem="activeTopbarItem" :mobileTopbarActive="mobileTopbarActive"
      @topbar-mobileactive="onTopbarMobileButtonClick" @menubutton-click="onMenuButtonClick"
      @topbar-menubutton-click="onTopbarMenuButtonClick" @topbaritem-click="onTopbarItemClick"
      @rightpanel-button-click="onRightPanelButtonClick" :searchActive="searchActive" @search-toggle="onSearchToggle"
      @search-click="onSearchClick" @search-hide="onSearchHide"></AppTopBar>

    <div class="menu-wrapper">
      <div class="layout-menu-container" @click="onMenuClick">
        <AppInlineMenu v-if="inlineMenuPosition === 'top' || inlineMenuPosition === 'both'"
          v-model:active="inlineMenuTopActive" @change-inlinemenu="onChangeInlineMenu" inlineMenuKey="top"
          :menuMode="menuMode"></AppInlineMenu>
        <AppMenu :model="menu" :menuMode="menuMode" :active="menuActive" :mobileMenuActive="mobileMenuActive"
          @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"></AppMenu>
        <!-- <AppInlineMenu v-if="inlineMenuPosition === 'bottom' || inlineMenuPosition === 'both'" v-model:active="inlineMenuBottomActive" @change-inlinemenu="onChangeInlineMenu" inlineMenuKey="bottom" :menuMode="menuMode"></AppInlineMenu> -->
      </div>
    </div>

    <div class="layout-main">
      <AppBreadcrumb></AppBreadcrumb>

      <div class="layout-content">
        <router-view />
      </div>

      <AppFooter :layoutMode="layoutMode" />
    </div>
    <div v-if="mobileMenuActive" class="layout-mask modal-in"></div>
  </div>
  <div v-if="$route.meta.plainLayout">
    <router-view />
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppInlineMenu from "./AppInlineMenu.vue";
import AppMenu from "./AppMenu.vue";
import AppFooter from "./AppFooter.vue";
import AppBreadcrumb from "./AppBreadcrumb.vue";
import EventBus from "./event-bus";

export default {
  emits: [
    "layout-mode-change",
    "menu-theme",
    "menuTheme",
    "topbar-theme",
    "topbarTheme",
    "layoutModeChange",
  ],
  props: {
    topbarTheme: String,
    menuTheme: String,
    layoutMode: String,
  },
  data() {
    return {
      permission_fks: localStorage.permission_fks,
      d_topbarTheme: this.topbarTheme,
      d_menuTheme: this.menuTheme,
      d_layoutMode: this.layoutMode,
      mobileTopbarActive: false,
      mobileMenuActive: false,
      search: false,
      searchClick: false,
      searchActive: false,
      menuMode: "static",
      inlineMenuClick: false,
      inlineMenuPosition: "bottom",
      inlineMenuTopActive: false,
      inlineMenuBottomActive: false,
      overlayMenuActive: false,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
      rotateMenuButton: false,
      topbarMenuActive: false,
      activeTopbarItem: null,
      darkMenu: false,
      theme: "blue",
      themes: [
        { name: "indigo", color: "#2f8ee5" },
        { name: "pink", color: "#E91E63" },
        { name: "purple", color: "#9C27B0" },
        { name: "deeppurple", color: "#673AB7" },
        { name: "blue", color: "#2196F3" },
        { name: "lightblue", color: "#03A9F4" },
        { name: "cyan", color: "#00BCD4" },
        { name: "teal", color: "#009688" },
        { name: "green", color: "#4CAF50" },
        { name: "lightgreen", color: "#8BC34A" },
        { name: "lime", color: "#CDDC39" },
        { name: "yellow", color: "#FFEB3B" },
        { name: "amber", color: "#FFC107" },
        { name: "orange", color: "#FF9800" },
        { name: "deeporange", color: "#FF5722" },
        { name: "brown", color: "#795548" },
        { name: "bluegrey", color: "#607D8B" },
      ],
      menuThemes: [
        { name: "light", color: "#FDFEFF" },
        { name: "dark", color: "#434B54" },
        { name: "indigo", color: "#1A237E" },
        { name: "bluegrey", color: "#37474F" },
        { name: "brown", color: "#4E342E" },
        { name: "cyan", color: "#006064" },
        { name: "green", color: "#2E7D32" },
        { name: "deeppurple", color: "#4527A0" },
        { name: "deeporange", color: "#BF360C" },
        { name: "pink", color: "#880E4F" },
        { name: "purple", color: "#6A1B9A" },
        { name: "teal", color: "#00695C" },
      ],
      topbarThemes: [
        { name: "lightblue", color: "#2E88FF" },
        { name: "dark", color: "#363636" },
        { name: "white", color: "#FDFEFF" },
        { name: "blue", color: "#1565C0" },
        { name: "deeppurple", color: "#4527A0" },
        { name: "purple", color: "#6A1B9A" },
        { name: "pink", color: "#AD1457" },
        { name: "cyan", color: "#0097A7" },
        { name: "teal", color: "#00796B" },
        { name: "green", color: "#43A047" },
        { name: "lightgreen", color: "#689F38" },
        { name: "lime", color: "#AFB42B" },
        { name: "yellow", color: "#FBC02D" },
        { name: "amber", color: "#FFA000" },
        { name: "orange", color: "#FB8C00" },
        { name: "deeporange", color: "#D84315" },
        { name: "brown", color: "#5D4037" },
        { name: "grey", color: "#616161" },
        { name: "bluegrey", color: "#546E7A" },
        { name: "indigo", color: "#3F51B5" },
      ],
      rightPanelActive: false,
      menuActive: true,
      menu: [],
    };
  },
  watch: {
    $route() {
      this.menuActive = this.isStatic() && !this.isMobile();
      this.$toast.removeAllGroups();
    },
    topbarTheme(newValue) {
      this.d_topbarTheme = newValue;
    },
    menuTheme(newValue) {
      this.d_menuTheme = newValue;
    },
    layoutMode(newValue) {
      this.d_layoutMode = newValue;
    },
  },
  mounted() {
    if (this.permission_fks) {
      this.permission_fks = this.permission_fks.split(",");
    } else {
      this.permission_fks = [];
    }
    this.loadMenu();
  },
  methods: {
    loadMenu() {
      this.menu = [
        {
          items: [
            { label: "Client", icon: "pi pi-home", to: "/clients" },
            {
              label: "Client Employees",
              icon: "pi pi-user",
              to: "/ClientEmployee",
            },
            {
              label: "Billing",
              icon: "pi pi-fw pi-book",
              items: [
                {
                  label: "Plan",
                  icon: "pi pi-fw pi-list",
                  to: "/billing/plan",
                },
                {
                  label: "Tax Invoice",
                  icon: "pi pi-fw pi-file-pdf",
                  to: "/billing/taxinvoice",
                },
                {
                  label: "Payment Receipt",
                  icon: "pi pi-fw pi-file-pdf",
                  to: "/billing/receipt",
                },
                {
                  label: "Client Payment",
                  icon: "pi pi-fw pi-file-pdf",
                  to: "/billing/clientPayment",
                },
                {
                  label: "Live Balance",
                  icon: "pi pi-fw pi-wallet",
                  to: "/billing/clientbalance",
                },
              ],
            },
            {
              label: "Parliament List",
              icon: "pi pi-fw pi-sitemap",
              items: [
                {
                  label: "Lok Sabha",
                  icon: "pi pi-fw pi-sliders-h",
                  to: "/parliament/loksabha",
                },
                {
                  label: "Vidhan Sabha",
                  icon: "pi pi-fw pi-sliders-h",
                  to: "/parliament/vidhansabha",
                },
                {
                  label: "Corporation",
                  icon: "pi pi-fw pi-sliders-h",
                  to: "/parliament/corporation",
                },
                {
                  label: "Zilla Parishad",
                  icon: "pi pi-fw pi-sliders-h",
                  to: "/parliament/zillaparishad",
                },
                {
                  label: "Panchayat Samiti",
                  icon: "pi pi-fw pi-sliders-h",
                  to: "/parliament/panchayatsamiti",
                },
                {
                  label: "Gram Panchayat",
                  icon: "pi pi-fw pi-sliders-h",
                  to: "/parliament/grampanchayat",
                },
                {
                  label: "Area",
                  icon: "pi pi-fw pi-sliders-h",
                  to: "/parliament/areas",
                },
                {
                  label: "Pincode",
                  icon: "pi pi-fw pi-sliders-h",
                  to: "/parliament/pincode",
                },
                {
                  label: "District",
                  icon: "pi pi-fw pi-sliders-h",
                  to: "/parliament/district",
                },
              ],
            },
            { label: "Users", icon: "pi pi-user", to: "/users" },
            {
              label: "Cloud Telephony",
              icon: "pi pi-cloud",
              items: [
                {
                  label: "DID Numbers",
                  icon: "pi pi-fw pi-sliders-h",
                  to: "/D_I_D/Numbers/free-num%bers",
                },
              ],
            },
            {
              label: "Settings",
              icon: "pi pi-cog",
              items: [
                {
                  label: "Default Tunes",
                  icon: "pi pi-bell pi-sliders-h",
                  to: "/settings/DefaultTunes",
                },
                {
                  label: "Admin SMS And Email Settings",
                  icon: "pi pi-envelope pi-sliders-h",
                  to: "/settings/AdminSMSAndEmailSetting",
                },
                {
                  label: "Client SMS And Email Settings",
                  icon: "pi pi-envelope pi-sliders-h",
                  to: "/settings/ClientSMSAndEmailSetting",
                },
                {
                  label: "Companies",
                  icon: "pi pi-sliders-h",
                  to: "/settings/Companies",
                }, 
                {
                  label: "SMS Sender Id",
                  icon: "pi pi-envelope pi-sliders-h",
                  to: "/settings/CompaniesSenderId",
                },
                {
                  label: "Voter Companies",
                  icon: "pi pi-sliders-v",
                  to: "/settings/ClientCompanies",
                },
                {
                  label: "Voter Designation",
                  icon: "pi pi-sliders-v",
                  to: "/settings/votersDesignation",
                },
                {
                  label: "Image Uploader",
                  icon: "pi pi-list pi-sliders-h",
                  to: "/settings/image-uploader",
                },
              ],
            },

            {
              label: "Support Tickets",
              icon: "pi pi-ticket",
              items: [
                { label: 'New Tickets', icon: 'pi pi-ticket', to: '/support-tickets/new-ticket' },
                { label: "Open Tickets", icon: "pi pi-question-circle", to: "/support-tickets/open-ticket" },
                { label: 'Closed Tickets', icon: 'pi pi-times-circle', to: '/support-tickets/closed-ticket' },
                // { label: 'Cancel Ticktes', icon: 'pi pi-times-circle', to: '/support-tickets/cancel-ticket' },
              ],
            },
          ],
        },
      ];
    },
    onDocumentClick() {
      if (!this.searchClick && this.searchActive) {
        this.onSearchHide();
      }

      if (!this.topbarItemClick) {
        this.activeTopbarItem = null;
        this.topbarMenuActive = false;
      }

      if (!this.menuClick) {
        if (this.isHorizontal() || this.isSlim()) {
          this.menuActive = false;
          EventBus.emit("reset-active-index");
        }

        if (this.mobileMenuActive) {
          this.mobileMenuActive = false;
        }

        if (this.isOverlay()) {
          this.menuActive = false;
        }

        this.hideOverlayMenu();
        this.unblockBodyScroll();
      }

      if (!this.rightPanelClick) {
        this.rightPanelActive = false;
      }

      if (!this.inlineMenuClick) {
        this.inlineMenuTopActive = false;
        this.inlineMenuBottomActive = false;
      }

      this.topbarItemClick = false;
      this.menuClick = false;
      this.rightPanelClick = false;
      this.searchClick = false;
      this.inlineMenuClick = false;
    },
    onSearchToggle() {
      this.searchActive = !this.searchActive;
      this.searchClick = true;
    },
    onSearchClick() {
      this.searchClick = true;
    },
    onSearchHide() {
      this.searchActive = false;
      this.searchClick = false;
    },
    isHorizontal() {
      return this.menuMode === "horizontal";
    },
    isSlim() {
      return this.menuMode === "slim";
    },
    isOverlay() {
      return this.menuMode === "overlay";
    },
    isStatic() {
      return this.menuMode === "static";
    },
    isDesktop() {
      return window.innerWidth > 991;
    },
    isMobile() {
      return window.innerWidth <= 991;
    },
    hideOverlayMenu() {
      this.rotateMenuButton = false;
      this.overlayMenuActive = false;
      this.staticMenuMobileActive = false;
    },
    onMenuButtonClick(event) {
      this.menuClick = true;
      this.menuActive = !this.menuActive;
      this.topbarMenuActive = false;
      this.topbarRightClick = true;
      this.rotateMenuButton = !this.rotateMenuButton;
      this.topbarMenuActive = false;

      if (this.isDesktop())
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      else {
        this.mobileMenuActive = !this.mobileMenuActive;
        if (this.mobileMenuActive) {
          this.blockBodyScroll();
        } else {
          this.unblockBodyScroll();
        }
      }

      event.preventDefault();
    },
    onTopbarMenuButtonClick(event) {
      this.topbarItemClick = true;
      this.topbarMenuActive = !this.topbarMenuActive;
      this.hideOverlayMenu();
      event.preventDefault();
    },
    onTopbarItemClick(event) {
      this.topbarItemClick = true;

      if (this.activeTopbarItem === event.item) this.activeTopbarItem = null;
      else this.activeTopbarItem = event.item;

      event.originalEvent.preventDefault();
    },
    onTopbarMobileButtonClick(event) {
      this.mobileTopbarActive = !this.mobileTopbarActive;
      event.preventDefault();
    },
    onRightPanelButtonClick(event) {
      this.rightPanelClick = true;
      this.rightPanelActive = !this.rightPanelActive;

      event.preventDefault();
    },
    onRightPanelClick() {
      this.rightPanelClick = true;
    },
    onMenuClick() {
      this.menuClick = true;
    },
    onRootMenuItemClick() {
      this.menuActive = !this.menuActive;
    },
    onMenuItemClick(event) {
      if (!event.item.items) {
        this.hideOverlayMenu();
        EventBus.emit("reset-active-index");
      }

      if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
        this.menuActive = false;
      }
    },
    onMenuModeChange(menuMode) {
      this.menuMode = menuMode;
      if (menuMode === "horizontal") {
        this.inlineMenuPosition = "top";
      }
    },
    onLayoutModeChange(menuColor) {
      this.$emit("layout-mode-change", menuColor);

      const layoutLink = document.getElementById("layout-css");
      const layoutHref = "assets/layout/css/layout-" + menuColor + ".css";
      this.replaceLink(layoutLink, layoutHref);

      const themeLink = document.getElementById("theme-css");
      const urlTokens = themeLink.getAttribute("href").split("/");
      urlTokens[urlTokens.length - 1] = "theme-" + menuColor + ".css";
      const newURL = urlTokens.join("/");

      this.replaceLink(themeLink, newURL, () => {
        this.$appState.isNewThemeLoaded = true;
      });
    },
    onInlineMenuPositionChange(position) {
      this.inlineMenuPosition = position;
    },
    onChangeInlineMenu(e, key) {
      if (key === "top") {
        if (this.inlineMenuBottomActive) {
          this.inlineMenuBottomActive = false;
        }
        this.inlineMenuTopActive = !this.inlineMenuTopActive;
      }
      if (key === "bottom") {
        if (this.inlineMenuTopActive) {
          this.inlineMenuTopActive = false;
        }
        this.inlineMenuBottomActive = !this.inlineMenuBottomActive;
      }

      this.inlineMenuClick = true;
    },
    changeTheme(theme) {
      this.theme = theme;
      this.changeStyleSheetUrl("theme-css", theme);
    },
    onTopbarThemeChange(theme) {
      this.$emit("topbar-theme", theme);

      const themeName = theme.name;
      const logo = document.getElementById("logo");

      if (
        themeName == "white" ||
        themeName == "yellow" ||
        themeName == "amber" ||
        themeName == "orange" ||
        themeName == "lime"
      ) {
        logo.src = "/assets/layout/images/logo-dark.svg";
      } else {
        logo.src = "/assets/layout/images/logo-light.svg";
      }
    },
    onMenuTheme(menuTheme) {
      this.$emit("menu-theme", menuTheme);
    },
    changeStyleSheetUrl(id, value) {
      const element = document.getElementById(id);
      const urlTokens = element.getAttribute("href").split("/");
      urlTokens[urlTokens.length - 2] = value;
      const newURL = urlTokens.join("/");
      this.replaceLink(element, newURL);
    },
    replaceLink(linkElement, href, callback) {
      const id = linkElement.getAttribute("id");
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute("href", href);
      cloneLinkElement.setAttribute("id", id + "-clone");

      linkElement.parentNode.insertBefore(
        cloneLinkElement,
        linkElement.nextSibling
      );

      cloneLinkElement.addEventListener("load", () => {
        linkElement.remove();
        cloneLinkElement.setAttribute("id", id);

        if (callback) {
          callback();
        }
      });
    },
    blockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.add("blocked-scroll");
      } else {
        document.body.className += " blocked-scroll";
      }
    },
    unblockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.remove("blocked-scroll");
      } else {
        document.body.className = document.body.className.replace(
          new RegExp(
            "(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
      }
    },
  },
  computed: {
    layoutContainerClass() {
      return [
        "layout-wrapper",
        "layout-menu-" + this.menuTheme + " layout-topbar-" + this.topbarTheme,
        {
          "layout-menu-static": this.menuMode === "static",
          "layout-menu-overlay": this.menuMode === "overlay",
          "layout-menu-overlay-active": this.overlayMenuActive,
          "layout-menu-slim": this.menuMode === "slim",
          "layout-menu-horizontal": this.menuMode === "horizontal",
          "layout-menu-active": this.menuActive,
          "layout-menu-mobile-active": this.mobileMenuActive,
          "layout-topbar-mobile-active": this.mobileTopbarActive,
          "layout-rightmenu-active": this.rightPanelActive,
          "layout-rtl": this.$appState.RTL,
          "p-input-filled": this.$appState.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
  },
  components: {
    AppTopBar: AppTopBar,
    AppInlineMenu: AppInlineMenu,
    // 'AppRightPanel': AppRightPanel,
    AppMenu: AppMenu,
    // 'AppConfig': AppConfig,
    AppFooter: AppFooter,
    AppBreadcrumb: AppBreadcrumb,
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
