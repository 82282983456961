<template>
	<div class="layout-footer p-d-flex p-ai-center p-p-4 p-shadow-2">
        <img id="footer-logo" :src="'https://storage.googleapis.com/meraneta_admin/emailerImages/149x38-01-SQUdZqA4Ci20240612170850.png'" alt="footer-logo" style="height: 2.70rem">
    </div>
</template>

<script>
	export default {
		name: "AppFooter",
		props: {
			layoutMode: {
				type: String,
				default: 'light'
			}
		},
		computed: {
			isRTL() {
				return this.$appState.RTL;
			}
		}
	}
</script>

<style scoped>

</style>