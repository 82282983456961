import { reactive } from "vue";

const state = reactive({
   templateReplyTypeList: [
      { label: 'Quick Reply Button', value: 1 },
      { label: 'Static Link Button', value: 2 },
      { label: 'Dynamic Link Button', value: 3 },
      { label: 'Mobile Number', value: 4 },
      { label: 'Email', value: 5 },
      { label: 'Aadhar Number', value: 6 },
      { label: 'Voter Id', value: 7 },
      { label: 'Pancard', value: 8 },
      { label: 'Driving Licence', value: 9 },
      { label: 'Vehicle Number', value: 10 },
      { label: 'Image', value: 11 },
      { label: 'Video', value: 12 },
      { label: 'OTP', value: 13 },
      { label: 'Text', value: 14 },
      { label: 'PDF or Word file', value: 15 },
      { label: 'Contact', value: 16 },
      { label: 'Location', value: 17 },
      { label: 'Dropdown', value: 18 },
      { label: 'Date', value: 19 },
      { label: 'Radio Button', value: 20 },
      { label: 'Passport Number', value: 21 },   
      { label: 'Stop Chatbot', value: 22 },

   ],
   ChannelIdList: [
      { label: 'WhatsApp', value: 1 },
      { label: 'RCS', value: 2 },
      { label: 'Sms', value: 3 },
      { label: 'Email', value: 4 },
   ],
   headerFileTypeList: [
      { value: 1, label: 'Image' },
      { value: 2, label: 'Document' },
      { value: 3, label: 'Video' },
      { value: 4, label: 'Audio' },
   ],
   actionTakingList: [
      { value: 1, label: 'Action Taking User' },
      { value: 2, label: 'Admin User' },
      { value: 3, label: 'Support User' },
      { value: 4, label: 'Voter' },
      { value: 5, label: 'Current User' }
   ],
   documentTypes: [
      { value: 1, label: "Appointment letter of admin" },
      { value: 2, label: "Appointment letter of owner" },
      { value: 3, label: "Appointment letter of co-ordinator" },
      { value: 4, label: "Purchase Order" },
      { value: 5, label: "Technical Information" },
      { value: 6, label: "Other" },
   ],
   actionTakingListForAdmin: [
      { value: 1, label: 'Owner' },
      { value: 2, label: 'Admin' },
      { value: 3, label: 'Co-ordinator' },
      { value: 4, label: 'Billing' }
   ]
});

export default { state };